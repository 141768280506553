import { makeSlugComponent } from '@curated-property/shared-pages';
import * as hotelOverrides from '../../../component-overrides';
import {
  getStaticPaths,
  getStaticProps,
} from '../../../utils/static-paths-and-props-functions';

export default function Slug(props) {
  const thisHotelComponentOverrides =
    hotelOverrides[props.ctyhocn.toLowerCase()] || {};
  const Comp = makeSlugComponent(thisHotelComponentOverrides);
  return (
    <Comp {...props} googleMapsKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY} />
  );
}

export { getStaticPaths, getStaticProps };
